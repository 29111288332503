<template>


  <div :style="{'background-color': sfondo_3, 'color':testo_sfondo_3}">
    <!-- Immagine FAQ Desktop-->
    <div class="section-img-faq">
      <img
          class="background-img-faq"
          id="img-faq"
          src="https://funer24-cdn.com/faq.svg"
          alt="">
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- /Immagine FAQ Desktop-->
    <!-- Immagine FAQ Mobile-->
    <div class="section-img-faq-mobile">
      <img
          class="background-img-faq"
          id="img-faq"
          src="https://ucarecdn.com/67fef486-a708-40c9-9ed0-3ea59b2bf9b4/-/crop/16:9/-/format/auto/"
          alt="">
      <!-- <div class="layer" :style="{'background-color': sfondo_2}"></div> -->
    </div>
    <!-- /Immagine FAQ Mobile-->
    <div class="section-faq" :style="{'background-color': sfondo_2 }">
      <div class="container-title-text-faq" :style="{'color':testo_sfondo_2 + '!important'}">
        <h3 class="title-faq">faq</h3>
      </div>
    </div>
    <div :style="{'background-color': sfondo_3 }">
      <div class="container-80 ">
        <div class="items-faq">
          <div
              v-for="item in faq"
              :key="item.id"
              class="item-faq"
              :id="item.id"
              @click="showhideTextFaq(item.id)"
          >
            <div class="info-item-faq">
              <div>
                <span class="title-item-faq">{{ item.domanda }}</span>
              </div>
              <!-- <hr class="hr-faq"> -->
              <div class="arrow-faq">
                <!-- <img class="arrow-down" :id="'arrow-'+faq.id" src="~@/assets/TemplateImg/arrow-icon/tendina.svg" alt=""> -->
                <span class="showplus" :id="'plus-'+item.id"></span>
              </div>
            </div>
            <div class="item-text-faq" :id="'text-'+item.id">
              <div v-if="item.img_risolte.length !== 0">
                <b-carousel class="carousel-faq"
                            id="carousel-1-faq"
                            :interval="4000"
                            controls
                            indicators
                            style="text-shadow: 1px 1px 2px #333;"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                >

                  <b-carousel-slide
                      v-for="photo in item.img_risolte"
                      :key="photo.id"
                      :img-src="photo.img + '-/crop/16:9/'"
                      class="custom-slider-img"
                  >

                  </b-carousel-slide>
                </b-carousel>
              </div>

              <p class="description-text" :style="{'color':testo_sfondo_3}" v-html="item.risposta">
              </p>
            </div>

            <hr class="hr-faq-bottom" :style="{'background-color':sfondo_2}">
          </div>
        </div>
        <br>
        <br>

      </div>
    </div>


  </div>
</template>


<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "Faq",
  computed: {
    ...mapGetters([
      'texture',
      'nome_azienda',
      'chi_siamo',
      'sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'faq',
    ]),
  },
  metaInfo() {
    return {
      title: 'Faq - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },

  data() {

    return {
      text_visibility_faq: null,
      arrow_rotate: null,
      plus_minus: null,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      slide: 0,
      sliding: null,
    }
  },

  methods: {

    ...mapActions([
      'listFAQ'
    ]),

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    showhideTextFaq: function (id) {

      this.text_visibility_faq = document.getElementById('text-' + id);
      this.plus_minus = document.getElementById('plus-' + id);
      if (this.plus_minus.classList.contains("showplus")) {
        this.plus_minus.classList.remove("showplus");
        this.plus_minus.classList.add("showminus");
      } else {
        this.plus_minus.classList.remove("showminus");
        this.plus_minus.classList.add("showplus");
      }
      // this.arrow_rotate = document.getElementById('arrow-'+id);
      // this.arrow_rotate.classList.toggle("rotate-arrow");
      this.text_visibility_faq.classList.toggle("toogle-faq");
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("img-faq").style.marginTop = "0px";
      } else {
        document.getElementById("img-faq").style.marginTop = "80px";
      }
    },

  },

  async created() {
    await this.listFAQ();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>


<style scoped>
@import '~@/assets/css/template_vega/faq.css';
</style>
